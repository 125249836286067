import { useOverview } from '@hooks/useOverview';
import useTranslations from '@hooks/useTranslations';
import { fetchEventsPages } from '@lib/prepr/events';
import CardGrid from '@organisms/CardGrid/CardGrid';
import { Events } from '@type-declarations/overviewItems';
import { QueryParameters } from '@type-declarations/prepr';

interface Props {
  title: string;
  initItems: Events[];
  initTotal: number;
  additionalQueryParams?: Omit<QueryParameters, 'host'>;
  initLimit?: number;
  hideIfEmpty?: boolean;
}

export default function EventGrid({
  title,
  initItems,
  initTotal,
  additionalQueryParams,
  initLimit,
  hideIfEmpty,
}: Props) {
  const t = useTranslations();
  const { items, hasNextPage, handleLoadMore, loading } = useOverview({
    initItems,
    initTotal,
    fetch: fetchEventsPages,
    additionalQueryParams,
    initLimit,
  });

  return (
    <CardGrid
      title={title}
      loading={loading}
      items={items}
      hasNextPage={hasNextPage}
      handleLoadMore={handleLoadMore}
      notFoundTitle={t.noEventsFoundTitle}
      notFoundText={t.noEventsFoundText}
      hideIfEmpty={hideIfEmpty}
    />
  );
}
